<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        size="xl"
        @ok="formSave"
        @show="modalOpen"
    >
        <b-overlay v-if="record !== null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form">
                <!-- id, active -->
                <form-id-active :record.sync="record"></form-id-active>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab basic -->
                        <b-tab active>
                            <template #title>
                                <b-icon v-if="!tabValidBasic"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('form.tab.basic')}}
                            </template>
                            <!-- meta -->
                            <b-form-group
                                :label="$tc('form.meta.data', 0)"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold p-0 pl-1"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-form-row>
                                    <!-- name -->
                                    <b-form-group
                                        id="group-meta.name"
                                        :label="$t('form.name')"
                                        class="col-sm-12 col-md-6"
                                        description=""
                                        label-class="mb-0"
                                        label-for="meta.name"
                                    >
                                        <b-form-input
                                            id="meta.name"
                                            v-model="record.meta.name"
                                            :formatter="__capitalize"
                                            :placeholder="$t('form.required_field')"
                                            :state="valid_metaName"
                                            required
                                            @change="createStamp()"
                                        ></b-form-input>
                                        <b-form-invalid-feedback :state="valid_metaName">
                                            {{$t('form.required_field')}}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    <!-- stamp -->
                                    <b-form-group
                                        id="group-meta.name"
                                        :label="$t('form.stamp')"
                                        class="col-sm-12 col-md-6"
                                        description=""
                                        label-class="mb-0"
                                        label-for="meta.name"
                                    >
                                        <b-form-input
                                            id="meta.name"
                                            v-model="record.stamp"
                                            :placeholder="$t('form.required_field')"
                                            readonly
                                            required
                                        ></b-form-input>
                                    </b-form-group>
                                </b-form-row>
                            </b-form-group>
                            <!-- content -->
                            <b-form-group
                                :label="$t('form.content')"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-form-row>
                                    <!-- order -->
                                    <b-form-group
                                        id="group-order"
                                        :label="$t('form.order')"
                                        class="col-sm-6 col-md-3"
                                        description=""
                                        label-class="mb-0"
                                        label-for="order"
                                    >
                                        <b-form-input
                                            id="order"
                                            v-model="record.order"
                                            type="number"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-form-row>
                            </b-form-group>
                        </b-tab>
                        <!-- tab locale -->
                        <b-tab :title="$t('form.tab.locale')">
                            <!-- locale-->
                            <b-form-group
                                :label="$t('form.locale.locale')"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold p-0 pl-1"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-card no-body>
                                    <b-tabs card pills>
                                        <b-tab v-for="locale in data.locale"
                                               :active="locale.locale === $i18n.locale"
                                               :title="locale.locale" style="background: white">
                                            <FormAccessLocale
                                                :locale="locale.locale"
                                                :record.sync="setRecordLocale[locale.locale]"
                                            />
                                        </b-tab>
                                    </b-tabs>
                                </b-card>
                            </b-form-group>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!--  -->
            </b-form>
        </b-overlay>
        <!-- alert error -->
        <b-alert
            v-if="errors !== null"
            :show="errors.message != null"
            class="mb-0"
            dismissible
            variant="danger"
            @dismissed="resetErrors"
        >
            <div v-html="__formatErrorMessage(errors.message)"></div>
        </b-alert>
    </b-modal>
</template>

<script>
import FormIdActive from "@/components/general/elements/formElements/FormIdActive";
import FormAccessLocale from "@/components/admin/access/FormAccessLocale";

export default {
    name: "ModalFormAccess",
    components: {FormAccessLocale, FormIdActive},
    props: {
        localeKey: null,
        uri: null,
        form: {},
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tabIndex: 0,
            valid: null,
            recordDefault: {
                id: 0,
                active: true,
                order: 0,
                stamp: null,
                meta: {
                    name: '',
                },
                locale: {},
            },
            // <-- set -->
            errorsDefault: {
                message: null,
            },
            errors: null,
            data: {},
            record: null,
        }
    },
    mounted() {
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        createStamp() {
            let basis = this.record.meta.name;
            if (this.__isNull(basis) || this.record.id !== 0) {
                this.record.stamp = null;
                return;
            }
            // <-- create stamp -->
            let stamp = basis.toLowerCase();
            stamp = stamp.replace(/ +(?= )/g, '');
            stamp = stamp.split(' ').join('_');
            // <--  -->
            this.record.stamp = stamp;
        },
        // <-- modal -->
        modalOpen() {
            this.resetErrors();
            this.valid = null;
            // <--  -->
            if (this.form.id > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name);
            });
        },
        // <-- form -->
        checkValidity() {
            this.valid = this.$refs.form.checkValidity();
            return this.valid;
        },
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            if (this.checkValidity()) {
                if (this.record.id > 0) {
                    this.baseUpdate();
                    return;
                }
                this.baseInsert();
            }
        },
        // <-- api -->
        loadNew() {
            this.showOverlay(true);
            // <-- record -->
            this.$set(this, 'record', this.__clone(this.recordDefault));
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignData`, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main + `/${this.form.id}`, config)
                .then((response) => {
                    // <-- status -->
                    this.$set(this, 'record', response.data.record);
                    // <-- rights -->
                    this.$set(this, 'data', response.data.data);
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                });
        },
        // <-- base -->
        baseInsert() {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- data -->
            let data = {
                'locale': this.$i18n.locale,
                'record': this.record,
            };
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.post(this.uri.main, data, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
        baseUpdate() {
            this.showOverlay(true);
            // <--  -->
            let record = this.record;
            // <-- clear errors -->
            this.resetErrors();
            // <-- data -->
            let data = {
                'locale': this.$i18n.locale,
                'record': record
            };
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.put(this.uri.main + `/${this.record.id}`, data, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
    },
    computed: {
        title: {
            get() {
                let record = this.record;
                if (this.form.id <= 0 || record === null) {
                    return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                }
                // <-- name -->
                let name = record.meta.name;
                Object.keys(record.locale).find((n) => {
                    if (n === this.$i18n.locale) {
                        if (!this.__isNull(record.locale[n].name)) {
                            name = record.locale[n].name;
                        }
                    }
                });
                // <--  -->
                return this.$tc(this.localeKey, 0) + ': ' + name;
            }
        },
        // <-- locale -->
        setRecordLocale() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        name: '',
                        notes: ''
                    });
                }
            });
            return this.record.locale;
        },
        // <-- meta -->
        valid_metaName() {
            if (this.valid === null) {
                return null;
            }
            return this.record.meta.name.length > 0 ? null : false;
        },
        // <-- tabs valid -->
        tabValidBasic() {
            if (this.valid === null) {
                return true;
            }
            // <-- check validity -->
            return this.valid_metaName === null ? true : this.valid_metaName;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
