<template>
    <div class="table-detail text-left bg-secondary-light p-2"
         v-html="content"
    >
    </div>
</template>

<script>
export default {
    name: "TableDetailHtml",
    components: {},
    props: {
        content: {
            type: String,
        }
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
